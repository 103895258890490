/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RoundWinner } from './RoundWinner';
import {
    RoundWinnerFromJSON,
    RoundWinnerFromJSONTyped,
    RoundWinnerToJSON,
} from './RoundWinner';

/**
 * 
 * @export
 * @interface RoundWinners
 */
export interface RoundWinners {
    /**
     * 
     * @type {number}
     * @memberof RoundWinners
     */
    trId: number;
    /**
     * 
     * @type {string}
     * @memberof RoundWinners
     */
    name: string;
    /**
     * 
     * @type {Array<RoundWinner>}
     * @memberof RoundWinners
     */
    winners: Array<RoundWinner>;
}

/**
 * Check if a given object implements the RoundWinners interface.
 */
export function instanceOfRoundWinners(value: object): value is RoundWinners {
    if (!('trId' in value) || value['trId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('winners' in value) || value['winners'] === undefined) return false;
    return true;
}

export function RoundWinnersFromJSON(json: any): RoundWinners {
    return RoundWinnersFromJSONTyped(json, false);
}

export function RoundWinnersFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoundWinners {
    if (json == null) {
        return json;
    }
    return {
        
        'trId': json['trId'],
        'name': json['name'],
        'winners': ((json['winners'] as Array<any>).map(RoundWinnerFromJSON)),
    };
}

export function RoundWinnersToJSON(value?: RoundWinners | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'trId': value['trId'],
        'name': value['name'],
        'winners': ((value['winners'] as Array<any>).map(RoundWinnerToJSON)),
    };
}

