/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TeamRanking } from './TeamRanking';
import {
    TeamRankingFromJSON,
    TeamRankingFromJSONTyped,
    TeamRankingToJSON,
} from './TeamRanking';

/**
 * 
 * @export
 * @interface Ranking
 */
export interface Ranking {
    /**
     * 
     * @type {number}
     * @memberof Ranking
     */
    tournamentGroupId: number;
    /**
     * 
     * @type {string}
     * @memberof Ranking
     */
    tournamentGroupName: string;
    /**
     * 
     * @type {string}
     * @memberof Ranking
     */
    tournamentGroupLongName: string;
    /**
     * 
     * @type {Array<TeamRanking>}
     * @memberof Ranking
     */
    rankings: Array<TeamRanking>;
}

/**
 * Check if a given object implements the Ranking interface.
 */
export function instanceOfRanking(value: object): value is Ranking {
    if (!('tournamentGroupId' in value) || value['tournamentGroupId'] === undefined) return false;
    if (!('tournamentGroupName' in value) || value['tournamentGroupName'] === undefined) return false;
    if (!('tournamentGroupLongName' in value) || value['tournamentGroupLongName'] === undefined) return false;
    if (!('rankings' in value) || value['rankings'] === undefined) return false;
    return true;
}

export function RankingFromJSON(json: any): Ranking {
    return RankingFromJSONTyped(json, false);
}

export function RankingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Ranking {
    if (json == null) {
        return json;
    }
    return {
        
        'tournamentGroupId': json['tournamentGroupId'],
        'tournamentGroupName': json['tournamentGroupName'],
        'tournamentGroupLongName': json['tournamentGroupLongName'],
        'rankings': ((json['rankings'] as Array<any>).map(TeamRankingFromJSON)),
    };
}

export function RankingToJSON(value?: Ranking | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tournamentGroupId': value['tournamentGroupId'],
        'tournamentGroupName': value['tournamentGroupName'],
        'tournamentGroupLongName': value['tournamentGroupLongName'],
        'rankings': ((value['rankings'] as Array<any>).map(TeamRankingToJSON)),
    };
}

