/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TournamentRound
 */
export interface TournamentRound {
    /**
     * 
     * @type {number}
     * @memberof TournamentRound
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TournamentRound
     */
    tournamentId: number;
    /**
     * 
     * @type {string}
     * @memberof TournamentRound
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TournamentRound
     */
    longName: string;
    /**
     * 
     * @type {boolean}
     * @memberof TournamentRound
     */
    leaf: boolean;
    /**
     * 
     * @type {number}
     * @memberof TournamentRound
     */
    parentId?: number;
}

/**
 * Check if a given object implements the TournamentRound interface.
 */
export function instanceOfTournamentRound(value: object): value is TournamentRound {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('tournamentId' in value) || value['tournamentId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('longName' in value) || value['longName'] === undefined) return false;
    if (!('leaf' in value) || value['leaf'] === undefined) return false;
    return true;
}

export function TournamentRoundFromJSON(json: any): TournamentRound {
    return TournamentRoundFromJSONTyped(json, false);
}

export function TournamentRoundFromJSONTyped(json: any, ignoreDiscriminator: boolean): TournamentRound {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tournamentId': json['tournamentId'],
        'name': json['name'],
        'longName': json['longName'],
        'leaf': json['leaf'],
        'parentId': json['parentId'] == null ? undefined : json['parentId'],
    };
}

export function TournamentRoundToJSON(value?: TournamentRound | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'tournamentId': value['tournamentId'],
        'name': value['name'],
        'longName': value['longName'],
        'leaf': value['leaf'],
        'parentId': value['parentId'],
    };
}

