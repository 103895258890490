/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GameResult } from './GameResult';
import {
    GameResultFromJSON,
    GameResultFromJSONTyped,
    GameResultToJSON,
} from './GameResult';

/**
 * 
 * @export
 * @interface GameSysAdmin
 */
export interface GameSysAdmin {
    /**
     * 
     * @type {number}
     * @memberof GameSysAdmin
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof GameSysAdmin
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof GameSysAdmin
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof GameSysAdmin
     */
    team1Id: string;
    /**
     * 
     * @type {string}
     * @memberof GameSysAdmin
     */
    team2Id: string;
    /**
     * 
     * @type {boolean}
     * @memberof GameSysAdmin
     */
    finished: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GameSysAdmin
     */
    bettingAllowed: boolean;
    /**
     * 
     * @type {string}
     * @memberof GameSysAdmin
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof GameSysAdmin
     */
    tournamentRoundId: string;
    /**
     * 
     * @type {{ [key: string]: GameResult; }}
     * @memberof GameSysAdmin
     */
    results: { [key: string]: GameResult; };
    /**
     * 
     * @type {boolean}
     * @memberof GameSysAdmin
     */
    intermediateResult: boolean;
}

/**
 * Check if a given object implements the GameSysAdmin interface.
 */
export function instanceOfGameSysAdmin(value: object): value is GameSysAdmin {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('team1Id' in value) || value['team1Id'] === undefined) return false;
    if (!('team2Id' in value) || value['team2Id'] === undefined) return false;
    if (!('finished' in value) || value['finished'] === undefined) return false;
    if (!('bettingAllowed' in value) || value['bettingAllowed'] === undefined) return false;
    if (!('tournamentRoundId' in value) || value['tournamentRoundId'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    if (!('intermediateResult' in value) || value['intermediateResult'] === undefined) return false;
    return true;
}

export function GameSysAdminFromJSON(json: any): GameSysAdmin {
    return GameSysAdminFromJSONTyped(json, false);
}

export function GameSysAdminFromJSONTyped(json: any, ignoreDiscriminator: boolean): GameSysAdmin {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date': (new Date(json['date'])),
        'state': json['state'],
        'team1Id': json['team1Id'],
        'team2Id': json['team2Id'],
        'finished': json['finished'],
        'bettingAllowed': json['bettingAllowed'],
        'text': json['text'] == null ? undefined : json['text'],
        'tournamentRoundId': json['tournamentRoundId'],
        'results': (mapValues(json['results'], GameResultFromJSON)),
        'intermediateResult': json['intermediateResult'],
    };
}

export function GameSysAdminToJSON(value?: GameSysAdmin | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'date': ((value['date']).toISOString()),
        'state': value['state'],
        'team1Id': value['team1Id'],
        'team2Id': value['team2Id'],
        'finished': value['finished'],
        'bettingAllowed': value['bettingAllowed'],
        'text': value['text'],
        'tournamentRoundId': value['tournamentRoundId'],
        'results': (mapValues(value['results'], GameResultToJSON)),
        'intermediateResult': value['intermediateResult'],
    };
}

