/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserMail
 */
export interface UserMail {
    /**
     * 
     * @type {string}
     * @memberof UserMail
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserMail
     */
    senderId: string;
    /**
     * 
     * @type {string}
     * @memberof UserMail
     */
    senderName: string;
    /**
     * 
     * @type {string}
     * @memberof UserMail
     */
    receiverId: string;
    /**
     * 
     * @type {string}
     * @memberof UserMail
     */
    receiverName: string;
    /**
     * 
     * @type {string}
     * @memberof UserMail
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof UserMail
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof UserMail
     */
    state: number;
    /**
     * 
     * @type {Date}
     * @memberof UserMail
     */
    date: Date;
}

/**
 * Check if a given object implements the UserMail interface.
 */
export function instanceOfUserMail(value: object): value is UserMail {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('senderId' in value) || value['senderId'] === undefined) return false;
    if (!('senderName' in value) || value['senderName'] === undefined) return false;
    if (!('receiverId' in value) || value['receiverId'] === undefined) return false;
    if (!('receiverName' in value) || value['receiverName'] === undefined) return false;
    if (!('subject' in value) || value['subject'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    return true;
}

export function UserMailFromJSON(json: any): UserMail {
    return UserMailFromJSONTyped(json, false);
}

export function UserMailFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMail {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'senderId': json['senderId'],
        'senderName': json['senderName'],
        'receiverId': json['receiverId'],
        'receiverName': json['receiverName'],
        'subject': json['subject'],
        'message': json['message'],
        'state': json['state'],
        'date': (new Date(json['date'])),
    };
}

export function UserMailToJSON(value?: UserMail | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'senderId': value['senderId'],
        'senderName': value['senderName'],
        'receiverId': value['receiverId'],
        'receiverName': value['receiverName'],
        'subject': value['subject'],
        'message': value['message'],
        'state': value['state'],
        'date': ((value['date']).toISOString()),
    };
}

