/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Game,
  GameEvent,
  GameResult,
  GameState,
  MatchStatistic,
  RestResult,
} from '../models/index';
import {
    GameFromJSON,
    GameToJSON,
    GameEventFromJSON,
    GameEventToJSON,
    GameResultFromJSON,
    GameResultToJSON,
    GameStateFromJSON,
    GameStateToJSON,
    MatchStatisticFromJSON,
    MatchStatisticToJSON,
    RestResultFromJSON,
    RestResultToJSON,
} from '../models/index';

export interface CountMatchesRequest {
    team1Id: string;
    team2Id: string;
}

export interface DeleteGameEventRequest {
    gameId: string;
    id: string;
}

export interface GetDailyGamesRequest {
    day: string;
    tenant: string;
}

export interface GetGameByIdRequest {
    gameId: string;
}

export interface GetGameEventsRequest {
    gameId: string;
}

export interface GetGameResultsRequest {
    gameId: string;
}

export interface GetGameStateRequest {
    gameId: string;
}

export interface GetGamesByTournamentRoundRequest {
    trId: number;
}

export interface GetMatchStatisticRequest {
    team1Id: string;
    team2Id: string;
}

export interface GetMatchesRequest {
    count: number;
    offset: number;
    team1Id: string;
    team2Id: string;
}

export interface GetNextDayWithGamesRequest {
    day: string;
    tenant: string;
}

export interface GetPreviousDayWithGamesRequest {
    day: string;
    tenant: string;
}

/**
 * 
 */
export class GameControllerApi extends runtime.BaseAPI {

    /**
     */
    async countMatchesRaw(requestParameters: CountMatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['team1Id'] == null) {
            throw new runtime.RequiredError(
                'team1Id',
                'Required parameter "team1Id" was null or undefined when calling countMatches().'
            );
        }

        if (requestParameters['team2Id'] == null) {
            throw new runtime.RequiredError(
                'team2Id',
                'Required parameter "team2Id" was null or undefined when calling countMatches().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/games/matches/{team1Id}/{team2Id}/count`.replace(`{${"team1Id"}}`, encodeURIComponent(String(requestParameters['team1Id']))).replace(`{${"team2Id"}}`, encodeURIComponent(String(requestParameters['team2Id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async countMatches(requestParameters: CountMatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countMatchesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteGameEventRaw(requestParameters: DeleteGameEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters['gameId'] == null) {
            throw new runtime.RequiredError(
                'gameId',
                'Required parameter "gameId" was null or undefined when calling deleteGameEvent().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteGameEvent().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/games/{gameId}/events/{id}`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters['gameId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async deleteGameEvent(requestParameters: DeleteGameEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.deleteGameEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDailyGamesRaw(requestParameters: GetDailyGamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Game>>> {
        if (requestParameters['day'] == null) {
            throw new runtime.RequiredError(
                'day',
                'Required parameter "day" was null or undefined when calling getDailyGames().'
            );
        }

        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling getDailyGames().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/games/daily/{tenant}/{day}`.replace(`{${"day"}}`, encodeURIComponent(String(requestParameters['day']))).replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GameFromJSON));
    }

    /**
     */
    async getDailyGames(requestParameters: GetDailyGamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Game>> {
        const response = await this.getDailyGamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGameByIdRaw(requestParameters: GetGameByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Game>> {
        if (requestParameters['gameId'] == null) {
            throw new runtime.RequiredError(
                'gameId',
                'Required parameter "gameId" was null or undefined when calling getGameById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/games/{gameId}`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters['gameId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameFromJSON(jsonValue));
    }

    /**
     */
    async getGameById(requestParameters: GetGameByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Game> {
        const response = await this.getGameByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGameEventsRaw(requestParameters: GetGameEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GameEvent>>> {
        if (requestParameters['gameId'] == null) {
            throw new runtime.RequiredError(
                'gameId',
                'Required parameter "gameId" was null or undefined when calling getGameEvents().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/games/{gameId}/events`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters['gameId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GameEventFromJSON));
    }

    /**
     */
    async getGameEvents(requestParameters: GetGameEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GameEvent>> {
        const response = await this.getGameEventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGameResultsRaw(requestParameters: GetGameResultsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GameResult>>> {
        if (requestParameters['gameId'] == null) {
            throw new runtime.RequiredError(
                'gameId',
                'Required parameter "gameId" was null or undefined when calling getGameResults().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/games/results/{gameId}`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters['gameId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GameResultFromJSON));
    }

    /**
     */
    async getGameResults(requestParameters: GetGameResultsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GameResult>> {
        const response = await this.getGameResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGameStateRaw(requestParameters: GetGameStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GameState>> {
        if (requestParameters['gameId'] == null) {
            throw new runtime.RequiredError(
                'gameId',
                'Required parameter "gameId" was null or undefined when calling getGameState().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/games/state/{gameId}`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters['gameId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameStateFromJSON(jsonValue));
    }

    /**
     */
    async getGameState(requestParameters: GetGameStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GameState> {
        const response = await this.getGameStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGamesByTournamentRoundRaw(requestParameters: GetGamesByTournamentRoundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Game>>> {
        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling getGamesByTournamentRound().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/games/tournament-round/{trId}`.replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GameFromJSON));
    }

    /**
     */
    async getGamesByTournamentRound(requestParameters: GetGamesByTournamentRoundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Game>> {
        const response = await this.getGamesByTournamentRoundRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMatchStatisticRaw(requestParameters: GetMatchStatisticRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MatchStatistic>> {
        if (requestParameters['team1Id'] == null) {
            throw new runtime.RequiredError(
                'team1Id',
                'Required parameter "team1Id" was null or undefined when calling getMatchStatistic().'
            );
        }

        if (requestParameters['team2Id'] == null) {
            throw new runtime.RequiredError(
                'team2Id',
                'Required parameter "team2Id" was null or undefined when calling getMatchStatistic().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/games/match-statistic/{team1Id}/{team2Id}`.replace(`{${"team1Id"}}`, encodeURIComponent(String(requestParameters['team1Id']))).replace(`{${"team2Id"}}`, encodeURIComponent(String(requestParameters['team2Id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MatchStatisticFromJSON(jsonValue));
    }

    /**
     */
    async getMatchStatistic(requestParameters: GetMatchStatisticRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MatchStatistic> {
        const response = await this.getMatchStatisticRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMatchesRaw(requestParameters: GetMatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Game>>> {
        if (requestParameters['count'] == null) {
            throw new runtime.RequiredError(
                'count',
                'Required parameter "count" was null or undefined when calling getMatches().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getMatches().'
            );
        }

        if (requestParameters['team1Id'] == null) {
            throw new runtime.RequiredError(
                'team1Id',
                'Required parameter "team1Id" was null or undefined when calling getMatches().'
            );
        }

        if (requestParameters['team2Id'] == null) {
            throw new runtime.RequiredError(
                'team2Id',
                'Required parameter "team2Id" was null or undefined when calling getMatches().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/games/matches/{team1Id}/{team2Id}/{count}/{offset}`.replace(`{${"count"}}`, encodeURIComponent(String(requestParameters['count']))).replace(`{${"offset"}}`, encodeURIComponent(String(requestParameters['offset']))).replace(`{${"team1Id"}}`, encodeURIComponent(String(requestParameters['team1Id']))).replace(`{${"team2Id"}}`, encodeURIComponent(String(requestParameters['team2Id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GameFromJSON));
    }

    /**
     */
    async getMatches(requestParameters: GetMatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Game>> {
        const response = await this.getMatchesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getNextDayWithGamesRaw(requestParameters: GetNextDayWithGamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['day'] == null) {
            throw new runtime.RequiredError(
                'day',
                'Required parameter "day" was null or undefined when calling getNextDayWithGames().'
            );
        }

        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling getNextDayWithGames().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/games/next-day/{tenant}/{day}`.replace(`{${"day"}}`, encodeURIComponent(String(requestParameters['day']))).replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getNextDayWithGames(requestParameters: GetNextDayWithGamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getNextDayWithGamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPreviousDayWithGamesRaw(requestParameters: GetPreviousDayWithGamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['day'] == null) {
            throw new runtime.RequiredError(
                'day',
                'Required parameter "day" was null or undefined when calling getPreviousDayWithGames().'
            );
        }

        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling getPreviousDayWithGames().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/games/previous-day/{tenant}/{day}`.replace(`{${"day"}}`, encodeURIComponent(String(requestParameters['day']))).replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getPreviousDayWithGames(requestParameters: GetPreviousDayWithGamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getPreviousDayWithGamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
