/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserGroupTournamentSubscription
 */
export interface UserGroupTournamentSubscription {
    /**
     * 
     * @type {string}
     * @memberof UserGroupTournamentSubscription
     */
    userGroupId: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupTournamentSubscription
     */
    userGroupName: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupTournamentSubscription
     */
    tournamentId: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupTournamentSubscription
     */
    tournamentName: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserGroupTournamentSubscription
     */
    subscribed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserGroupTournamentSubscription
     */
    isDeletable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserGroupTournamentSubscription
     */
    deletable?: boolean;
}

/**
 * Check if a given object implements the UserGroupTournamentSubscription interface.
 */
export function instanceOfUserGroupTournamentSubscription(value: object): value is UserGroupTournamentSubscription {
    if (!('userGroupId' in value) || value['userGroupId'] === undefined) return false;
    if (!('userGroupName' in value) || value['userGroupName'] === undefined) return false;
    if (!('tournamentId' in value) || value['tournamentId'] === undefined) return false;
    if (!('tournamentName' in value) || value['tournamentName'] === undefined) return false;
    if (!('subscribed' in value) || value['subscribed'] === undefined) return false;
    if (!('isDeletable' in value) || value['isDeletable'] === undefined) return false;
    return true;
}

export function UserGroupTournamentSubscriptionFromJSON(json: any): UserGroupTournamentSubscription {
    return UserGroupTournamentSubscriptionFromJSONTyped(json, false);
}

export function UserGroupTournamentSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGroupTournamentSubscription {
    if (json == null) {
        return json;
    }
    return {
        
        'userGroupId': json['userGroupId'],
        'userGroupName': json['userGroupName'],
        'tournamentId': json['tournamentId'],
        'tournamentName': json['tournamentName'],
        'subscribed': json['subscribed'],
        'isDeletable': json['isDeletable'],
        'deletable': json['deletable'] == null ? undefined : json['deletable'],
    };
}

export function UserGroupTournamentSubscriptionToJSON(value?: UserGroupTournamentSubscription | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userGroupId': value['userGroupId'],
        'userGroupName': value['userGroupName'],
        'tournamentId': value['tournamentId'],
        'tournamentName': value['tournamentName'],
        'subscribed': value['subscribed'],
        'isDeletable': value['isDeletable'],
        'deletable': value['deletable'],
    };
}

