/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HighscoreInfo
 */
export interface HighscoreInfo {
    /**
     * 
     * @type {number}
     * @memberof HighscoreInfo
     */
    tournamentRoundId: number;
    /**
     * 
     * @type {string}
     * @memberof HighscoreInfo
     */
    tournamentName: string;
    /**
     * 
     * @type {string}
     * @memberof HighscoreInfo
     */
    tournamentId: string;
    /**
     * 
     * @type {number}
     * @memberof HighscoreInfo
     */
    rank: number;
    /**
     * 
     * @type {number}
     * @memberof HighscoreInfo
     */
    absolutePos: number;
    /**
     * 
     * @type {number}
     * @memberof HighscoreInfo
     */
    count: number;
}

/**
 * Check if a given object implements the HighscoreInfo interface.
 */
export function instanceOfHighscoreInfo(value: object): value is HighscoreInfo {
    if (!('tournamentRoundId' in value) || value['tournamentRoundId'] === undefined) return false;
    if (!('tournamentName' in value) || value['tournamentName'] === undefined) return false;
    if (!('tournamentId' in value) || value['tournamentId'] === undefined) return false;
    if (!('rank' in value) || value['rank'] === undefined) return false;
    if (!('absolutePos' in value) || value['absolutePos'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    return true;
}

export function HighscoreInfoFromJSON(json: any): HighscoreInfo {
    return HighscoreInfoFromJSONTyped(json, false);
}

export function HighscoreInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HighscoreInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'tournamentRoundId': json['tournamentRoundId'],
        'tournamentName': json['tournamentName'],
        'tournamentId': json['tournamentId'],
        'rank': json['rank'],
        'absolutePos': json['absolutePos'],
        'count': json['count'],
    };
}

export function HighscoreInfoToJSON(value?: HighscoreInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tournamentRoundId': value['tournamentRoundId'],
        'tournamentName': value['tournamentName'],
        'tournamentId': value['tournamentId'],
        'rank': value['rank'],
        'absolutePos': value['absolutePos'],
        'count': value['count'],
    };
}

