/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupMemberId } from './GroupMemberId';
import {
    GroupMemberIdFromJSON,
    GroupMemberIdFromJSONTyped,
    GroupMemberIdToJSON,
} from './GroupMemberId';
import type { UserId } from './UserId';
import {
    UserIdFromJSON,
    UserIdFromJSONTyped,
    UserIdToJSON,
} from './UserId';

/**
 * 
 * @export
 * @interface GroupMemberChange
 */
export interface GroupMemberChange {
    /**
     * 
     * @type {GroupMemberId}
     * @memberof GroupMemberChange
     */
    groupMemberId?: GroupMemberId;
    /**
     * 
     * @type {UserId}
     * @memberof GroupMemberChange
     */
    userId?: UserId;
    /**
     * 
     * @type {string}
     * @memberof GroupMemberChange
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupMemberChange
     */
    state?: string;
    /**
     * 
     * @type {Date}
     * @memberof GroupMemberChange
     */
    date?: Date;
}

/**
 * Check if a given object implements the GroupMemberChange interface.
 */
export function instanceOfGroupMemberChange(value: object): value is GroupMemberChange {
    return true;
}

export function GroupMemberChangeFromJSON(json: any): GroupMemberChange {
    return GroupMemberChangeFromJSONTyped(json, false);
}

export function GroupMemberChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupMemberChange {
    if (json == null) {
        return json;
    }
    return {
        
        'groupMemberId': json['groupMemberId'] == null ? undefined : GroupMemberIdFromJSON(json['groupMemberId']),
        'userId': json['userId'] == null ? undefined : UserIdFromJSON(json['userId']),
        'userName': json['userName'] == null ? undefined : json['userName'],
        'state': json['state'] == null ? undefined : json['state'],
        'date': json['date'] == null ? undefined : (new Date(json['date'])),
    };
}

export function GroupMemberChangeToJSON(value?: GroupMemberChange | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'groupMemberId': GroupMemberIdToJSON(value['groupMemberId']),
        'userId': UserIdToJSON(value['userId']),
        'userName': value['userName'],
        'state': value['state'],
        'date': value['date'] == null ? undefined : ((value['date']).toISOString()),
    };
}

