/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BettingReminderText
 */
export interface BettingReminderText {
    /**
     * 
     * @type {string}
     * @memberof BettingReminderText
     */
    currentText: string;
    /**
     * 
     * @type {number}
     * @memberof BettingReminderText
     */
    validDays: number;
    /**
     * 
     * @type {Date}
     * @memberof BettingReminderText
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof BettingReminderText
     */
    standardText: string;
}

/**
 * Check if a given object implements the BettingReminderText interface.
 */
export function instanceOfBettingReminderText(value: object): value is BettingReminderText {
    if (!('currentText' in value) || value['currentText'] === undefined) return false;
    if (!('validDays' in value) || value['validDays'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('standardText' in value) || value['standardText'] === undefined) return false;
    return true;
}

export function BettingReminderTextFromJSON(json: any): BettingReminderText {
    return BettingReminderTextFromJSONTyped(json, false);
}

export function BettingReminderTextFromJSONTyped(json: any, ignoreDiscriminator: boolean): BettingReminderText {
    if (json == null) {
        return json;
    }
    return {
        
        'currentText': json['currentText'],
        'validDays': json['validDays'],
        'createdAt': (new Date(json['createdAt'])),
        'standardText': json['standardText'],
    };
}

export function BettingReminderTextToJSON(value?: BettingReminderText | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'currentText': value['currentText'],
        'validDays': value['validDays'],
        'createdAt': ((value['createdAt']).toISOString()),
        'standardText': value['standardText'],
    };
}

